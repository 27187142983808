.input-form {
  flex-direction: column;
  justify-content: stretch;
  display: flex;
}

.label {
  margin-bottom: .5em;
  font-size: .7em;
  font-weight: 700;
  display: block;
}

.text-area {
  width: 100%;
  resize: vertical;
  box-sizing: border-box;
  border-radius: 5px;
  padding: .8em;
  font-family: sans-serif;
  font-size: 1rem;
}

.submit-button {
  height: 3em;
  width: 8em;
  color: #fff;
  cursor: pointer;
  background-color: #0043c8;
  border: none;
  border-radius: 3px;
  justify-content: center;
  align-self: flex-end;
  align-items: center;
  font-size: 1rem;
  display: flex;
}

.submit-button:active {
  background-color: #00359e;
}

.lds-dual-ring {
  width: 1em;
  height: 1em;
  display: inline-block;
}

.lds-dual-ring:after {
  content: " ";
  width: 1em;
  height: 1em;
  border: 2px solid #fff;
  border-color: #fff #0000;
  border-radius: 50%;
  animation: lds-dual-ring 1.2s linear infinite;
  display: block;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.result-container {
  background-color: #eee;
  border-radius: 5px;
  margin-bottom: 1em;
  padding: 1em;
}

.result-prompt {
  margin-bottom: 1em;
  display: flex;
}

.result-response {
  display: flex;
}

.result-label {
  width: 7em;
  flex-shrink: 0;
  font-weight: 600;
}

.result-value {
  flex: auto;
}

body {
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  font-size: 18px;
}

.container {
  width: 100%;
  max-width: 40rem;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 1em;
}

/*# sourceMappingURL=index.1cbba68e.css.map */
