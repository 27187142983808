body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 18px;
  padding: 0;
}

.container {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  padding: 1em;
  box-sizing: border-box;
}
