.result-container {
  padding: 1em;
  margin-bottom: 1em;
  border-radius: 5px;
  background-color: #eeeeee;
}

.result-prompt {
  margin-bottom: 1em;
  display: flex;
}

.result-response {
  display: flex;
}

.result-label {
  width: 7em;
  font-weight: 600;
  flex-shrink: 0;
}

.result-value {
  flex: 1 1 auto;
}
