.input-form {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.label {
  display: block;
  margin-bottom: 0.5em;
  font-weight: 700;
  font-size: 0.7em;
}

.text-area {
  width: 100%;
  font-family: sans-serif;
  resize: vertical;
  font-size: 1rem;
  padding: 0.8em;
  box-sizing: border-box;
  border-radius: 5px;
}

.submit-button {
  height: 3em;
  width: 8em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 67, 200);
  color: white;
  border: none;
  border-radius: 3px;
  font-size: 1rem;
  align-self: flex-end;
  cursor: pointer;
}

.submit-button:active {
  background-color: rgb(0, 53, 158);
}

.lds-dual-ring {
  display: inline-block;
  width: 1em;
  height: 1em;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
